import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Layout from '../../Components/Layout/Layout'
import { Space } from '../../Components/Space'
import {getTreatment} from '../../../i18/Datas/treatments'
import ImageGallery from 'react-image-gallery'

import LinkToAppointmentPage from '../Appointment/LinkToAppointmentPage'
const Container = styled.div``

const ImageGalleryContainer = styled.div`
  .image-gallery-icon {
    :hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  .image-gallery-thumbnail.active {
    border-color: ${(props) => props.theme.colors.primary};
  }
  .image-gallery-thumbnail {
    :hover {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }
`

function Treatment({ t, i18n }) {
  const { id } = useParams()
  const treatment = getTreatment({id, language : i18n.language})
  return (
    <Container>
      <Space medium />
      <Space medium />
      <Layout>
        <Typography variant='h3' align='center' color='primary'>
          {treatment.info.title}
        </Typography>
        {treatment.info.moreDescription.map((description, index) => (
          <>
          <Space medium />
        <Typography variant='h5' paragraph>
          {description}
        </Typography>
        </>
        ))}
      </Layout>
      <Space medium />
      <ImageGalleryContainer>
            <ImageGallery items={treatment.gallery} />
        </ImageGalleryContainer>
      <Space medium />
      <LinkToAppointmentPage />
    </Container>
  )
}

export default withTranslation()(Treatment)
