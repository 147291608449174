import React from 'react'
import styled from 'styled-components'
import callToActionImage from '../../Assets/images/set-appointment.jpg'
import { Typography, Button } from '@mui/material'

import { CallToAction } from '../../Components/CallToAction'
import { withTranslation } from 'react-i18next'

const TypographyLight = styled(Typography)`
  color: ${(props) => props.theme.colors.secondaryText};
`

function LinkToAppointmentPage({ t }) {
    return (
        <CallToAction image={callToActionImage}>
            <TypographyLight paragraph variant='h3' align='center'>
                {t('HomePage.SetAppointmentPartTitle')}
            </TypographyLight>
            <Button href='/appointment' variant='outlined' disableRipple size='large' color='secondary'>
                {t('HomePage.SetAppointmentPartLinkTitle')}
            </Button>
        </CallToAction>
    )
}

export default withTranslation()(LinkToAppointmentPage)
