import { Link as DefaultLink } from 'react-router-dom'
import styled from 'styled-components'

export const Link = styled(DefaultLink)`
  color: currentColor;
  text-decoration: none;
  :visited {
    color: currentColor;
  }
`

export const Anchor = styled.a`
  color: currentColor;
  text-decoration: none;
  :visited {
    color: currentColor;
  }
`