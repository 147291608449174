import React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import ImageGallery from 'react-image-gallery'

import aboutUs from '../../../i18/Datas/aboutUs'
import Doctors from '../Doctors/Doctors'
import { Space } from '../../Components/Space'
import Layout from '../../Components/Layout'

const Container = styled.div``
const ImageGalleryContainer = styled.div`
  .image-gallery-icon {
    :hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  .image-gallery-thumbnail.active {
    border-color: ${(props) => props.theme.colors.primary};
  }
  .image-gallery-thumbnail {
    :hover {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }
`

function AboutUs({ t, i18n }) {
    const clinicAboutUs = aboutUs(i18n.language)
    return (
        <Container>
            <Layout>
                <Space large />
                <Space large />
                <Typography variant='h3' component='h1' color='primary'>
                    {t('AboutUsPage.Title')}
                </Typography>
                <Space large />
                <ImageGalleryContainer>
                    <ImageGallery items={clinicAboutUs.images} />
                </ImageGalleryContainer>
                <Space medium />
                <Typography variant='h6' paragraph>
                    {clinicAboutUs.description}
                </Typography>
                <Space large />
            </Layout>
            <Doctors/>
        </Container>
    )
}
export default withTranslation()(AboutUs)
