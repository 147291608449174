export const routes = {
  home: '/',
  contactUs: '/contact-us',
  appointment: '/appointment',
  treatment: '/treatments/:id',
  treatments: '/treatments',
  services: '/services',
  doctors: '/doctors',
  aboutUs: '/about-us',
  emergency: '/emergency'
}
