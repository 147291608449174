const prices = {
    en: [
            {
                category: "Emegency prices",
                prices: [
                    {
                        title: "Survey including 2 photos",
                        price: "550"
                    },
                    {
                        title: "Examination including 2 photos and cleaning of tooth stones.",
                        price: "850"
                    },
                    {
                        title: "Hygiene supplements",
                        price: "110"
                    },
                    {
                        title: "Acute examination (Assessment of acute problem) leading to treatment in the same session",
                        price: "550"
                    },
                    {
                        title: "Local and regional anetesi",
                        price: "140"
                    }
                ]
            }
        ],
    no: [
        {
            category: "Vaktpriser",
            prices: [
                {
                    title: "Undersøkelse inkludert 2 bilder",
                    price: "550"
                },
                {
                    title: "Undersøkelse inkludert 2 bilder og rengjøring av tannstein.",
                    price: "850"
                },
                {
                    title: "Hygienetilskudd",
                    price: "110"
                },
                {
                    title: "Akutt undersøkelse (vurdering av akutt problem) som fører til behandling i samme økt",
                    price: "550"
                },
                {
                    title: "Lokal og regional anetesi",
                    price: "140"
                }
            ]
        }
    ]
}


export default function getPrices(language) {
    return prices[language];
}
