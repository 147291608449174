import React from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

import Image from './Image'
import Layout from '../Layout'
import { Link } from '../Link'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${ (props) => !props.textPlace && 'center'};
`
const TextContainer = styled.div`
  position: absolute;
  width: 100%;
`
const Title = styled(Typography)`
  color: ${(props) => props.theme.colors.primaryLight};
`
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.colors.primaryLight} !important;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    width: fit-content;
    align-self: flex-end;
    min-width: 8em !important;
  }
`
const LinkContainer = styled.div`
  display: flex;
  margin-top: 3em;
`
export function ImageSection({ image, imageSize, textPlace,  title, link, linkTitle }) {
  return (
      <Container textPlace={textPlace}>
      <Image
              image={image}
              imageSize={imageSize}
        size='500px'
        imageProps={{
          style: {
            marginBottom: 0
          }
        }}
      />
      <TextContainer>
        <Layout>
          <Title
            variant='h3'
            component='div'
            align={'left'}  // : 'center'
            fontWeight='bold'
          >
            {title}
          </Title>
          {!!link && (
            <LinkContainer>
              <Link to={link}>
                <StyledButton
                  style={{ boxShadow: 'none' }}
                  variant='contained'
                  disableRipple
                  fullWidth={false}
                  size='large'
                >
                  {linkTitle}
                </StyledButton>
              </Link>
            </LinkContainer>
          )}
        </Layout>
      </TextContainer>
    </Container>
  )
}
