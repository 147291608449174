import React from 'react'
import styled from 'styled-components'
import { IconButton } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  ul {
    background-color: ${(props) => props.theme.colors.background};
  }
`

export function LanguageSelector ({ languages = [], currentFlag }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (onClick) => () => {
    if (typeof onClick === 'function') {
      onClick()
    }
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        id='basic-button'
        aria-controls='basic-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
      >
        {currentFlag}
      </IconButton>
      <StyledMenu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {languages.map((lang, index) => (
          <MenuItem
            key={`${lang.name}-${index}`}
            onClick={handleClose(lang.onClick)}
            disableRipple
          >
            {lang.content}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
