import React from 'react'
import {
  FormControl,
  FormHelperText,
  TextField,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material'

import { Space } from '../Space'

export const Input = ({
  label,
  required,
  value,
  onChange,
  name,
  multiline,
  error,
  isSelect,
  options = []
}) => {
  if (isSelect) {
    return (
      <>
        <Space />
        <FormControl>
          <InputLabel id='type'>{label}</InputLabel>
          <Select
            labelId='type'
            id='type-select'
            label={label}
            variant='outlined'
            required={required}
            fullWidth
            value={value}
            onChange={onChange}
            name={name}
          >
            {options.map((opt, index) => {
              return (
                <MenuItem key={`${opt.value}-${index}`} value={opt.value}>
                  {opt.name}
                </MenuItem>
              )
            })}
          </Select>
          {!!error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      </>
    )
  }
  return (
    <FormControl>
      <TextField
        label={label}
        variant='outlined'
        style={{ marginTop: 11 }}
        required={required}
        fullWidth
        value={value}
        onChange={onChange}
        name={name}
        multiline={multiline}
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export const inputModes = {
  decimal: 'decimal',
  email: 'email',
  none: 'none',
  numeric: 'numeric',
  search: 'search',
  tel: 'tel',
  text: 'text',
  url: 'url'
}
