import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'

const Link = styled.a`
  color: currentColor;
  text-decoration: none;
  :visited {
    color: currentColor;
  }
`
const IconInnerContainer = styled.div`
  display: flex;
  transition: transform 400ms ease-in-out;
  transition-delay: 300ms;
  svg {
    width: 150px;
    height: 150px;
    fill: currentColor;
  }
`
const IconOuterContainer = styled.div`
  display: flex;
  transition: transform 300ms ease-in-out;
`
const Name = styled.div`
  transition: transform 300ms ease-in-out;
  transform: scale(0);
`
const Container = styled.div`
  display: flex;
  width: 250px;
  height: 300px;
  background: ${(props) =>
    props.dark ? props.theme.colors.primaryDark : props.theme.colors.primary};
  color: ${(props) => props.theme.colors.background};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  :hover ${IconOuterContainer} {
    transform: scale(1.2) translateY(-2.5em);
  }
  :hover ${IconInnerContainer} {
    transform: rotateY(360deg);
  }
  :hover ${Name} {
    transform: scale(1);
  }
`

export function IconBox ({ Icon, link, name, dark }) {
  return (
    <Container dark={dark}>
      <IconOuterContainer>
        <Link href={link} target='_blank' rel='noreferrer'>
          <IconInnerContainer>
            <Icon />
          </IconInnerContainer>
        </Link>
      </IconOuterContainer>
      <Link href={link} target='_blank' rel='noreferrer'>
        <Name>
          <Typography>{name}</Typography>
        </Name>
      </Link>
    </Container>
  )
}
