import React from 'react'
import styled from 'styled-components'
import { ImageSection } from '../../Components/ImageSection'
import { withTranslation } from 'react-i18next'
import setAppointment from '../../Assets/images/set-appointment.jpg'

import RequestAppointment from './RequestAppointment'

const Container = styled.div``

function Appointment({ t }) {
    return (
        <Container>
            <ImageSection image={setAppointment} title={t('SetAppointment.ImageTitle')} />
            <RequestAppointment />
        </Container>
    )
}
export default withTranslation()(Appointment)
