const prices = {
    en: [
            {
                title: "General Services",
                prices: [
                    {
                        title: "Survey including 2 photos",
                        price: "550"
                    },
                    {
                        title: "Examination including 2 photos and cleaning of tooth stones.",
                        price: "850"
                    },
                    {
                        title: "Hygiene supplements",
                        price: "110"
                    },
                    {
                        title: "Acute examination (Assessment of acute problem) leading to treatment in the same session",
                        price: "550"
                    },
                    {
                        title: "Local and regional anetesi",
                        price: "140"
                    }
                ]
            },
            {
                title: "Preservative and endodontic treatment",
                prices: [
                    {
                        title: "Preparation and filling with tooth-colored filling material,1 surface / Small filling",
                        price: "650"
                    },
                    {
                        title: "Preparation and filling with tooth-colored filling material, 2 surfaces / Between large filling",
                        price: "1200"
                    }
                ]
            }
        ],
    no: [
        {
            title: "Generelle tjenester",
            prices: [
                {
                    title: "Årlig undersøkelse/kontroll, 2stk røntgenbilder og tannsteinrens/ puss(recall)",
                    price: "1100"
                },
                {
                    title: "Enkel konsultasjon/ undersøkelse akutt",
                    price: "695"
                },
                {
                    title: "Enkel konsultasjon/ us hos spesialist (trygderefusjon trekkes ifra beløpet)",
                    price: "1200"
                },
                {
                    title: "Omfattende undersøkelse hos spesialist (trygderefusjon trekkes ifra beløpet)",
                    price: "1500"
                },
                {
                    title: "Undersøkelse/utredning omfattende inkl. Kostnadsoverslag (henv,brev) ",
                    price: "900"
                },
                {
                    title: "Etterkontroller (egenandel)",
                    price: "330"
                },
                {
                    title: "Ikke møtt/ sen avbestilling, minste gebyr",
                    price: "800"
                }
            ]
        },
        {
            title: "Anestesi",
            prices: [
                {
                    title: "Lokalbedøvelse- Lokal/regional. ",
                    price: "185"
                }
            ]
        },
        {
            title: "  ",
            prices: [
                {
                    title: "Digital røntgen bilde, 1 enkelt ",
                    price: "130"
                },
                {
                    title: "OPG bilde ",
                    price: "530"
                },
                {
                    title: "2BW ",
                    price: "230"
                },
                {
                    title: "Foto pr stk ",
                    price: "70"
                }
            ]
        },
        {
            title: "Kosmetikk behandling (Bleking):",
            prices: [
                {
                    title: "Hjemmebleking av tenner 1 kjeve inkl. 1 Skinne +1 sprøyte blekemiddel/ instr. ",
                    price: "2000"
                },
                {
                    title: "Hjemmebleking av tenner begge kjever samtidig: 2 Skinner / 2 stk blekemiddel/ instr.",
                    price: "3600"
                }
            ]
        },
        {
            title: "Usynlig tannregulering:",
            prices: [
                {
                    title: "Inman aligner regulering pr kjeve*, pris fra ",
                    price: "20000"
                },
                {
                    title: "Inman aligner regulering 2 kjever*, fra pris ",
                    price: "36000"
                },
                {
                    title: "Invisalign Go/ Dentinalign usynlig regulering 1 kjeve*",
                    price: "23000"
                },
                {
                    title: "Invisalign Go/ Dentinalign 2 kjever*",
                    price: "39000"
                }
            ]
        },
        {
            title: "Gull-/ Porselensinnlegg:",
            prices: [
                {
                    title: "Gull-/ Porseleninnlegg inkl. Tekniker, 1 flate ",
                    price: "3100"
                },
                {
                    title: "Gull-/ Porselensinnlegg inkl. Tekniker, 2 flater ",
                    price: "5000"
                },
                {
                    title: "Gull-/ Porselensinnlegg inkl. Tekniker, 3 flater ",
                    price: "5800"
                },
                {
                    title: "Gull-/ Porselensinnlegg inkl. Tekniker, 4 flater ",
                    price: "6500"
                }
            ]
        },
        {
            title: "Ekstraksjon:",
            prices: [
                {
                    title: "Trekking av tann ukomplisert (en rot) *",
                    price: "1080"
                },
                {
                    title: "Trekking av tann ukomplisert (2 eller flere røtter) *",
                    price: "1300"
                },
                {
                    title: "Fjerning av visdomstann ukomplisert*",
                    price: "1400"
                },
                {
                    title: "*Tilleggsutgift ved suturering og/ eller innsetting av spongostan.",
                    price: "250"
                }
            ]
        },
        {
            title: "Kirurgi:",
            prices: [
                {
                    title: "Kirurgisk fjerning av retinert tann eller tannrot(egenandel) 45min-1 time",
                    price: "2200"
                },
                {
                    title: "Timepris Kirurgi (beregnes av tiden utover første timen ) inkluderes i egenandel",
                    price: "1800"
                },
                {
                    title: "Hygienetillegg / smitteverntiltak",
                    price: "185"
                },
                {
                    title: "Kirurgisk oppdekking ( inkl. engangsutstyr/ material) ",
                    price: "375"
                },
                {
                    title: "Implantat kirurgisk oppdekking",
                    price: "600"
                }
            ]
        },
        {
            title: "Kompositt:",
            prices: [
                {
                    title: "Kompositt / plastfylling , 1 flate ",
                    price: "900"
                },
                {
                    title: "Kompositt / plastfylling , 2 flate ",
                    price: "1400"
                },
                {
                    title: "Kompositt / plastfylling , 3 flate ",
                    price: "1700"
                },
                {
                    title: "Kompositt / plastfylling , 4 flate eller stor oppbygging",
                    price: "1900-2200"
                }
            ]
        },
        {
            title: "Glassionomer:",
            prices: [
                {
                    title: "Tannfarget fylling ( glassionomer), 1 flate",
                    price: "600"
                },
                {
                    title: "Tannfarget fylling ( glassionomer), 2 flate",
                    price: "980"
                },
                {
                    title: "Tannfarget fylling ( glassionomer), 3 flate",
                    price: "1250"
                },
                {
                    title: "Tannfarget fylling ( glassionomer), 4 flate",
                    price: "1380"
                },
                {
                    title: "Midlertidig fylling (IRM) ",
                    price: "600"
                }
            ]
        },
        {
            title: "Rotfylling/ rotbehandling:",
            prices: [
                {
                    title: "1 kanal (fortann) ",
                    price: "3950"
                },
                {
                    title: "2 kanaler",
                    price: "4500"
                },
                {
                    title: "3 kanaler",
                    price: "5500"
                },
                {
                    title: "4 kanaler",
                    price: "5700"
                },
                {
                    title: "Timepris rotfylling",
                    price: "2400"
                },
                {
                    title: "* Tillegg ved maskinell rotbehandling per seanse",
                    price: "200"
                }
            ]
        },
        {
            title: "Øvrige tjenester/behandlinger:",
            prices: [
                {
                    title: "Grundig tannrens i begge kjever (scaling, polering, evt. Air flow) 30 min",
                    price: "900"
                },
                {
                    title: "Tannrens med Air flow eller EMS Perioflow ",
                    price: "600"
                },
                {
                    title: "Incisjon av abscess (egenandel) ",
                    price: "500"
                }
            ]
        },
        {
            title: "Protetikk:",
            prices: [
                {
                    title: "Kroner/bropilar ( metallkeram) ",
                    price: "5900"
                },
                {
                    title: "Morrison/GULL krone ( fullgullkrone) ",
                    price: "6900"
                },
                {
                    title: "Zirkonkrone/emax/Captek/bropilar , høyestetisk ",
                    price: "6400"
                },
                {
                    title: "Broledd/mellomledd/ekstensjonsledd metallkeram",
                    price: "3700"
                },
                {
                    title: "Broledd zirkon/emax/Captek , høyestetisk ",
                    price: "4100"
                },
                {
                    title: "Helprotese: over eller underkjeve (norsk produsert) ",
                    price: "13000"
                },
                {
                    title: "Kopiprotese, helover/under (norsk produsert) ",
                    price: "13000"
                },
                {
                    title: "Helsett/ helover og helunderkjeveprotese , begge kjever (norsk produsert) ",
                    price: "22500"
                },
                {
                    title: "Partiell protese(delprotese)/ Kromkobolt (vitalium) ",
                    price: "13300"
                },
                {
                    title: "Partiell protese trådklammer",
                    price: "8500"
                },
                {
                    title: "Rebasering,foring av løs protese ( indirekte), inkl. tekniker utg. ",
                    price: "3200"
                },
                {
                    title: "Rebasering,foring av løs protese ( direkte) ",
                    price: "2100"
                },
                {
                    title: "Øvrig arbeid eller behandling i forbindelse med fast protetikk(time takst) ",
                    price: "2000"
                },
                {
                    title: "Skallfasett, pr. stk.",
                    price: "5000"
                },
                {
                    title: "Støpt konus med stift inkl. tekniker utgifter ",
                    price: "3000"
                },
                {
                    title: "Prefabrikert stift med konus ",
                    price: "2100"
                },
                {
                    title: "Tannbeskytter (myk type) ",
                    price: "2100"
                },
                {
                    title: "Bittskinne TMD, Egenandel (hard type) inkludert us./utredning ",
                    price: "1650"
                }
            ]
        },
        {
            title: "Avansert protetikk:",
            prices: [
                {
                    title: "Dekkprotese ",
                    price: "13000"
                },
                {
                    title: "Teleskop feste",
                    price: "7000"
                },
                {
                    title: "Krone(MK) med patentfeste til protese",
                    price: "6200"
                },
                {
                    title: "dekkprotese på 2 implantater(lokator fester) inkl tekn",
                    price: "29000"
                }
            ]
        },
        {
            title: "Implantatbehandling:",
            prices: [
                {
                    title: "Implantatkirurgi (fiksturinnsetting) kun 1 fikstur og/eller 1.fikstur",
                    price: "12000"
                },
                {
                    title: "Ved 2 eller flere implantatfiksturer ( pris av den 2.fiksturen ,3.fiksturen , osv)",
                    price: "10000"
                },
                {
                    title: "Implantatkrone/bropilar (alt inkl.) ",
                    price: "11000"
                },
                {
                    title: "Implantatbroledd * Det kan komme ekstrakostnad til material(membran, kunstig ben, titan pins) og arbeid i forbindelse med bentransplantasjon, benvolumsøkning eller benaugmentasjon.",
                    price: "4900"
                }
            ]
        },
        {
            title: "Prøvetaking:",
            prices: [
                {
                    title: "Spyttprøve + taking og innsending av prøve til bakteriologiske, biokjemiske og Patologiske laboratorier(egenandel) ",
                    price: "600"
                }
            ]
        },
        {
            title: "Vakttjeneste:",
            prices: [
                {
                    title: "Det legges et prosent tillegg til vanlig honorar på 40-60% eller et minste tillegg på kr 750, avhengig av tidsforbruk og type behandling , på avtaler utover ordinær arbeidstid i ukedager( man-fre) I helger ( lør/søn) legges det et tillegg på 75% av vanlig honorar. Røde dager/ Hellige dager 75-100% tillegg. Minstetillegg kan gjelde ved kun konsultasjon.",
                    price: ""
                }
            ]
        }
    ]
}


export default function getPrices(language) {
    return prices[language];
}
