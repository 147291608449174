import React from 'react'
import styled from 'styled-components'
import { Typography, Grid } from '@mui/material'
import { withTranslation } from 'react-i18next'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'

import { ImageSection } from '../../Components/ImageSection'
import Layout from '../../Components/Layout'
import { Map } from '../../Components/Map'
import { Space } from '../../Components/Space'
import { IconBox } from '../../Components/IconBox'

import clinicImageOne from '../../Assets/images/clinic-image1.png'
import clinicImageTwo from '../../Assets/images/clinic-image2.png'
import header from '../../Assets/images/contact-us.jpg'

const Container = styled.div``
const socials = [
    {
        name: 'WhatsApp',
        icon: WhatsAppIcon,
        link: process.env.REACT_APP_WHATSAPP
    },
    {
        name: 'Facebook',
        icon: FacebookIcon,
        link: process.env.REACT_APP_FACEBOOK
    },
    {
        name: 'Instagram',
        icon: InstagramIcon,
        link: process.env.REACT_APP_INSTAGRAM
    }
]
const Link = styled.a`
  color: currentColor;
  text-decoration: none;
  :visited {
    color: currentColor;
  }
`
const Dimmer = styled.div`
  position: fixed;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: #00000047;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const Image = styled.img`
  width: 400px;
  height: 300px;
  object-fit: cover;
  object-position: center;
  transition: transform 300ms ease;
`
const ImageContainer = styled.div`
  z-index: 2;
  :hover ${Image} {
    transform: scale(1.5);
    z-index: 2;
  }
  :hover ${Dimmer} {
    width: 100vw;
    height: 100vh;
  }
`

function ContactUs({ t }) {
    return (
        <Container>
            <ImageSection image={header} title={t('ContactUsPage.ImageTitle')} />
            <Layout>
                <Space large />
                <Typography variant='h3' component='h1'>
                    {t('ContactUsPage.Title')}
                </Typography>
                <Space medium />
                <Typography variant='h6' paragraph color='primary'>
                    {t('ContactUsPage.Description')}
                </Typography>
                <Space medium />
                <Typography>{t('Footer.Address')}</Typography>
                <Typography>{t('Footer.OpeningHours')}</Typography>
                <Typography>
                    <Link href={`mailto:${t('Footer.Email')}`}>{t('Footer.Email')}</Link>
                </Typography>
                <Typography>
                    <Link href={`tel:${t('Footer.Telephone')}`}>
                        {t('Footer.Telephone')}
                    </Link>
                </Typography>
                <Space large />
                <Grid container justifyContent='center' columnSpacing={5}>
                    <Grid item>
                        <ImageContainer src={clinicImageOne}>
                            <Image src={clinicImageOne} />
                            <Dimmer />
                        </ImageContainer>
                    </Grid>
                    <Grid item>
                        <ImageContainer src={clinicImageTwo}>
                            <Image src={clinicImageTwo} />
                            <Dimmer />
                        </ImageContainer>
                    </Grid>
                </Grid>
                <Space large />
                <Typography variant='h3' component='h3'>
                    {t('ContactUsPage.SocialNetworksTitle')}
                </Typography>
                <Space />
                <Grid container justifyContent='center' columnSpacing={5}>
                    {socials.map((social, index) => (
                        <Grid key={index} item>
                            <IconBox
                                link={social.link}
                                name={social.name}
                                Icon={social.icon}
                                dark={index % 2}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Space large />
            </Layout>
            <Map
                center={[
                    62.73697,
                    7.16068
                ]}
                zoom={15}
            >
            </Map>
        </Container>
    )
}
export default withTranslation()(ContactUs)
