import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.index % 2
      ? props.theme.colors.primary
      : props.theme.colors.primaryDark};
  padding: 2em;
  height: 495px;
  user-select: none;
  ${(props) =>
    props.zoom &&
    `transition: transform 200ms ease;
  :hover {
    transform: scale(1.1);
  }`}
`
const Image = styled.img`
  width: 300px !important;
  height: 300px !important;
  border-radius: 150px;
  object-fit: cover;
  object-position: top;
`
const Text = styled(Typography)`
  color: ${(props) => props.theme.colors.secondaryText};
`
const Link = styled.a`
  color: currentColor;
  text-decoration: none;
  :visited {
    color: currentColor;
  }
`
export function SmallDoctorCard({ name, specialist, image, index, email, zoom }) {
  return (
    <Container index={index} zoom={zoom}>
      <Image src={image} alt={name} />
      <Text variant='h4' component='div'>
        {name}
      </Text>
      <Text variant='h5' paragraph>
        {specialist}
      </Text>
      {!!email && (
        <Text variant='h6' paragraph>
          <Link href={`mailto:${email}`}>{email}</Link>
        </Text>
      )}
    </Container>
  )
}