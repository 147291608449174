import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import EuroRoundedIcon from '@mui/icons-material/EuroRounded'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  margin: 10px;
  border-radius: 4px;
  position: relative;
  padding-top: 2em;
  padding-bottom: 1em;
`
const PriceContainer = styled.ul`
  display: flex;
  flex-direction: column;
  border: 3px solid ${(props) => props.theme.colors.accent}2b;
  border-radius: 6px;
  width: 80%;
  box-sizing: border-box;
  padding: 1em;
`
const PriceRow = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 1em;
  :nth-child(2n) {
    background-color: ${(props) => props.theme.colors.background};
  }
`
const Title = styled.div`
  display: flex;
  position: absolute;
  top: -19px;
  background-color: white;
  padding: 0 1em;
`
const Price = styled(Typography)`
  display: flex;
  align-items: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
export function Service ({ category, prices = [] }) {
  return (
    <Container>
      <Title>
        <Typography variant='h6' color='primary'>
          {category}
        </Typography>
      </Title>
      <PriceContainer>
        {prices.map((price, index) => (
          <PriceRow key={price.title + '-' + index}>
            <Typography color='GrayText'>{price.title}</Typography>
            <Price>
              {/*<EuroRoundedIcon />*/}
              {price.price}
            </Price>
          </PriceRow>
        ))}
      </PriceContainer>
    </Container>
  )
}
