import React from 'react'
import PropTypes from 'prop-types'
import { Map as PigeonMap, Marker as PigeonMarker } from 'pigeon-maps'
import styled, { keyframes } from 'styled-components'

const MarkerHolder = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
`
const pulsate = keyframes`
0% {
  transform: scale(0.1, 0.1);
  opacity: 0;
}
50% {
  opacity: 1;
}
100% {
  transform: scale(1.5, 1.5);
  opacity: 0;
}
`
const MarkerPuls = styled.div`
  background: ${(props) => {
        if (props.light) {
            return props.theme.colors.accent
        }
        if (props.lighter) {
            return props.theme.colors.lighterBackground
        }
        if (props.dark) {
            return props.theme.colors.primaryText
        }
        return props.theme.colors.primary
    }};
  border-radius: 50%;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  position: relative;
  margin: 11px 0px 0px -12px;
  z-index: -2;
  ::after {
    content: '';
    background: ${(props) => {
        if (props.light) {
            return props.theme.colors.accent
        }
        if (props.lighter) {
            return props.theme.colors.lighterBackground
        }
        if (props.dark) {
            return props.theme.colors.primaryText
        }
        return props.theme.colors.darkPrimary
    }}21;
    border-radius: 50%;
    height: ${(props) => props.effectSize}px;
    width: ${(props) => props.effectSize}px;
    top: -${(props) => props.effectPosition}px;
    right: -${(props) => props.effectPosition}px;
    position: absolute;
    animation: ${pulsate} 3s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    animation-delay: 1.1s;
    z-index: -3;
  }
`
const SecondPuls = styled.div`
  ::after {
    content: '';
    background: ${(props) => {
        if (props.light) {
            return props.theme.colors.accent
        }
        if (props.lighter) {
            return props.theme.colors.lighterBackground
        }
        if (props.dark) {
            return props.theme.colors.primaryText
        }
        return props.theme.colors.darkPrimary
    }}21;
    border-radius: 50%;
    height: ${(props) => props.effectSize}px;
    width: ${(props) => props.effectSize}px;
    top: -${(props) => props.effectPosition}px;
    right: -${(props) => props.effectPosition}px;
    position: absolute;
    animation: ${pulsate} 3s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    animation-delay: 1.6s;
    z-index: -2;
  }
`

function Marker({
    lat,
    lng,
    light,
    lighter,
    bulletSize,
    effectSize,
    dark,
    ...props
}) {
    const effectPosition = effectSize / 2 - bulletSize / 2
    return (
        <MarkerHolder>
            <MarkerPuls
                effectSize={effectSize}
                size={bulletSize}
                effectPosition={effectPosition}
                light={light}
                lighter={lighter}
                dark={dark}
            >
                <SecondPuls
                    effectSize={effectSize}
                    size={bulletSize}
                    effectPosition={effectPosition}
                    light={light}
                    lighter={lighter}
                    dark={dark}
                />
            </MarkerPuls>
        </MarkerHolder>
    )
}
Marker.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    light: PropTypes.bool,
    lighter: PropTypes.bool,
    dark: PropTypes.bool,
    effectSize: PropTypes.number,
    bulletSize: PropTypes.number
}
Marker.defaultProps = {
    light: false,
    lighter: false,
    dark: false,
    effectSize: 200,
    bulletSize: 15
}

const MainContainer = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
`

function Map({
    center,
    zoom,
    lightMap,
    darkMap,
    silverMap,
    mapHeight,
    className,
    retro,
    ...props
}) {
    return (
        <MainContainer height={mapHeight} className={className}>
            <PigeonMap
                defaultCenter={center}
                defaultZoom={zoom}
            >
                <PigeonMarker width={20} anchor={center}/>
            </PigeonMap>
        </MainContainer>
    )
}
Map.propTypes = {
    center: PropTypes.exact({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
    }).isRequired,
    zoom: PropTypes.number,
    apiKey: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(Marker),
        PropTypes.objectOf(Marker),
        PropTypes.any
    ]),
    lightMap: PropTypes.bool,
    darkMap: PropTypes.bool,
    silverMap: PropTypes.bool,
    retro: PropTypes.bool,
    mapHeight: PropTypes.number,
    className: PropTypes.string
}
Map.defaultProps = {
    zoom: 11,
    children: null,
    lightMap: false,
    darkMap: false,
    silverMap: false,
    retro: false,
    mapHeight: 400,
    className: ''
}

export { Map, Marker }
