import doctorOne from "../../App/Assets/images/Doctors/doctor1.jpg"
import doctorTwo from "../../App/Assets/images/Doctors/doctor1.jpg"
import doctorThree from "../../App/Assets/images/Doctors/doctor1.jpg"
import doctorFour from "../../App/Assets/images/Doctors/doctor1.jpg"

const doctors = {
    en:
        [
            {
                name: "Airan Rastegarian",
                image: doctorOne,
                description: [],
                specialist: "klinikksjef"
            },
            {
                name: "Anas Alsaadi",
                image: doctorTwo,
                description: [],
                specialist: "tannlege"
            },
            {
                name: "Usman Malik",
                image: doctorThree,
                description: [],
                specialist: "tannlege"
            },
            {
                name: "Manzar Din",
                image: doctorFour,
                description: [],
                specialist: "implantat protetikk"
            }
            //,
            //{
            //    name: "Meysam Moafi",
            //    image: doctorOne,
            //    description: [],
            //    specialist: "oral kirurg"
            //}
        ],
    no:
        [
            {
                name: "Airan Rastegarian",
                image: doctorOne,
                description: [],
                specialist: "klinikksjef"
            },
            {
                name: "Anas Alsaadi",
                image: doctorTwo,
                description: [],
                specialist: "tannlege"
            },
            {
                name: "Usman Malik",
                image: doctorThree,
                description: [],
                specialist: "tannlege"
            },
            {
                name: "Manzar Din",
                image: doctorFour,
                description: [],
                specialist: "implantat protetikk"
            }
            //,
            //{
            //    name: "Meysam Moafi",
            //    image: doctorOne,
            //    description: [],
            //    specialist: "oral kirurg"
            //}
        ]
}


export default function getDoctors(language) {
    return doctors[language]
}
