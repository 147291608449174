import styled from 'styled-components'

export const Space = styled.div`
  width: 100%;
  background-color: transparent;
  height: ${(p) => {
    if (p.large) {
      return '4em'
    }
    if (p.medium) {
      return '2em'
    }
    return '1em'
  }};
`
