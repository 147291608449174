import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link } from '../Link'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  align-items: center;
  max-width: 320px;
`
const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
`
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.primary};
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
`

const Title = styled.span`
  font-size: 13px;
  color: ${(props) => props.theme.colors.primary};
  line-height: 18px;
  font-weight: 300;
  text-transform: capitalize;
`
const Description = styled.p`
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 1.7;
  letter-spacing: 0;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 1rem;
  box-sizing: border-box;
`

function Feature({
  image,
  title,
  description,
  className,
  imageProps,
  link,
  ...props
}) {
  const feature = (
    <MainContainer className={className}>
      <Image
        className={className ? `${className}-image` : ''}
        src={image}
        alt={title}
        {...imageProps}
      />
      <DetailsContainer
        className={className ? `${className}-details-container` : ''}
      >
        <Title className={className ? `${className}-position` : ''}>
          {title}
        </Title>
        <Description className={className ? `${className}-description` : ''}>
          {description}
        </Description>
      </DetailsContainer>
    </MainContainer>
  )
  if (!!link) {
    return <Link to={link}>{feature}</Link>
  }
  return feature
}

Feature.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  socials: PropTypes.any,
  circle: PropTypes.bool,
  card: PropTypes.bool,
  shadow: PropTypes.bool,
  className: PropTypes.string,
  imageProps: PropTypes.object
}
Feature.defaultProps = {
  socials: null,
  circle: false,
  card: false,
  shadow: false,
  className: ''
}

export default Feature
