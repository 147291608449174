import React from 'react'
import styled from 'styled-components'
import { Typography,Grid } from '@mui/material'
import {LabelImportant} from '@mui/icons-material'
import { Space } from '../../Components/Space'

const Container = styled.div`
  background: ${(props) =>
    props.index % 2
      ? props.theme.colors.primary
      : props.theme.colors.primaryDark};
  padding: 2em;
  user-select: none;
  ${(props) =>
    props.zoom &&
    `transition: transform 200ms ease;
  :hover {
    transform: scale(1.1);
  }`}
`
const DoctorContainer = styled.div`
  text-align:center;
`
const Image = styled.img`
  width: 300px !important;
  height: 300px !important;
  border-radius: 150px;
  object-fit: cover;
  object-position: top;
`
const Text = styled(Typography)`
  color: ${(props) => props.theme.colors.secondaryText};
`
const Link = styled.a`
  color: currentColor;
  text-decoration: none;
  :visited {
    color: currentColor;
  }
`
export function DoctorCard({ name, specialist, image, index, description, email, zoom }) {
  return (
    <Container index={index} zoom={zoom}>
      <Grid container direction="row" spacing={3}>
        <DoctorContainer item key={1} >
          <Image src={image} alt={name} />
          <Text key={1} variant='h4' component='div'>{name}</Text>
          <Text key={2} variant='h5' component='div'>{specialist}</Text>
          {!!email && (
            <Text variant='h6' paragraph>
              <Link href={`mailto:${email}`}>{email}</Link>
            </Text>
          )}
        </DoctorContainer>
        <Grid item key={2} xs={8}>
          {description.map((detail, index) => 
          (
            <Text key={index} variant='h6' paragraph>{detail}</Text>
          ))}
        </Grid>
      </Grid>
      <Space medium />
    </Container>
  )
}
