import axios from 'axios'
export function submitAppointmentRequest({
    token,
    fullName,
    phoneNumber,
    description,
    email
}) {
    return axios.post(
        process.env.REACT_APP_RECAPTCHA_REQUEST_URL,
        {
            fullName,
            phoneNumber,
            description,
            email
        },
        {
            headers: {
                token
            }
        }
    )
}
