import styled from 'styled-components'

export const CallToAction = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: ${(props) =>
    props.image
      ? `linear-gradient(
45deg, #000000a3, #000000a3),url(${props.image})`
      : props.theme.colors.primaryDark};
  min-height: 150px;
  box-sizing: border-box;
  padding: 5em 2em;
  justify-content: center;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    min-height: 440px;
  }
`
