import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const MainContainer = styled.div``
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 20px;
  @media screen and (min-width: ${(props) => props.theme.sizes.tablet}) {
    padding: 0 30px;
  }
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    flex-direction: row-reverse;
    max-width: ${(props) => props.maxWidth}px;
    align-self: center;
    margin: auto;
  }
`
const Section = styled.section`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  margin-bottom: 0;
  @media screen and (min-width: ${(props) => props.theme.sizes.tablet}) {
    margin-bottom: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    flex: 3;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
`

function SidebarLayout ({ className, maxWidth, ...props }) {
  const { children } = props
  return (
    <MainContainer className={className}>
      <LayoutContainer id='layout-container' maxWidth={maxWidth}>
        <Section id='section-content'>{children}</Section>
      </LayoutContainer>
    </MainContainer>
  )
}
SidebarLayout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  maxWidth: PropTypes.number
}
SidebarLayout.defaultProps = {
  className: '',
  maxWidth: 1270
}

export default SidebarLayout
