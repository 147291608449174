import implantImage from '../../App/Assets/images/Treatments/implant.jpg'
import invisibleOralImage from '../../App/Assets/images/Treatments/invisible-oral.jpg'
import dentalFearImage from '../../App/Assets/images/Treatments/dental-fear.jpg'

import aboutUsOne from "../../App/Assets/images/AboutUs/about-us1.jpg"
import aboutUsTwo from "../../App/Assets/images/AboutUs/about-us2.jpg"
import aboutUsThree from "../../App/Assets/images/AboutUs/about-us3.jpg"
import aboutUsFour from "../../App/Assets/images/AboutUs/about-us4.jpg"
import aboutUsFive from "../../App/Assets/images/AboutUs/about-us5.jpg"


const treatments = {
  no: [
    {
      id: 1,
      title: 'IMPLANTAT',
      description:
        'Kirurgisk innsetting av implantat, også i samarbeid med en oralkirurg. Fremstilling av implantatkroner og broer , samt implantat forankrede proteser',
      image: implantImage
      // ,
      // link: '/'
    },
    {
      id: 2,
      title: 'USYNLIG TANNREGULERING',
      description:
        'Vi utfører flere typer usynlige tannregulering/ alignere. invisalign, clear align og inman aligner',
      image: invisibleOralImage
      // ,
      // link: '/'
    },
    {
      id: 3,
      title: 'TANNLEGESKREKK',
      description:
        'Vi tar imot pasienter med tannlegeskrekk/odontofobi. Vi har tannleger som er godt opplært til å sørge for en trygg behandling av redde og engstelige pasienter, både voksne og barn. medlem i NOFOBI',
      // moreDescription:[],
      image: dentalFearImage
      // ,
      // link: '/treatments/3'
    }
  ],
  en: [
    {
      id: 1,
      title: 'IMPLANTANT',
      description:
        'Kirurgisk innsetting av implantat, også i samarbeid med en oralkirurg. Fremstilling av implantatkroner og broer , samt implantat forankrede proteser',
      image: implantImage
      // ,
      // link: '/'
    },
    {
      id: 2,
      title: 'USYNLIG TANNREGULERING',
      description:
        'Vi utfører flere typer usynlige tannregulering/ alignere. invisalign, clear align og inman aligner',
      image: invisibleOralImage
      // ,
      // link: '/'
    },
    {
      id: 3,
      title: 'TANNLEGESKREKK',
      description:
        'Vi tar imot pasienter med tannlegeskrekk/ odontofobia. Vi har tannleger som godt opplært til å sørge for en trygg behandling av redde pasienter, både voksene og barn. Vi er medlem i NOFOBI',
      // moreDescription:[],
      image: dentalFearImage
      // ,
      // link: '/treatments/3'
    }
  ]
}

const treatmentsGallery = [
  {
    id:3,
    gallery : [
            {
                original: aboutUsOne,
                thumbnail: aboutUsOne
            },
            {
                original: aboutUsTwo,
                thumbnail: aboutUsTwo
            },
            {
                original: aboutUsThree,
                thumbnail: aboutUsThree
            },
            {
                original: aboutUsFour,
                thumbnail: aboutUsFour
            },
            {
                original: aboutUsFive,
                thumbnail: aboutUsFive
            }
    ]
  }
]

export default function getTreatments(language) {
  return treatments[language]
}


export function getTreatment({id, language}) {
  return {
      info : treatments[language].find(t => t.id == id),
      gallery : treatmentsGallery.find(tg => tg.id == id).gallery
    }
}
