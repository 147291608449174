import React from 'react'
import styled from 'styled-components'
import { Typography, Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import { withTranslation } from 'react-i18next'

import Carousel from '../../Components/Carousel'
import { ImageSection } from '../../Components/ImageSection'
import Layout from '../../Components/Layout'
import Feature from '../../Components/Feature'
import { Space } from '../../Components/Space'
import { CallToAction } from '../../Components/CallToAction'
import { SmallDoctorCard } from '../../Components/DoctorCard/SmallDoctorCard'

import welcome from '../../Assets/images/welcome.svg'
import emergency from '../../Assets/images/emergency.jpg'
import treatments from '../../../i18/Datas/treatments'
import doctors from '../../../i18/Datas/doctors'

const Title = styled(Typography)`
  color: ${(props) => props.theme.colors.primaryDark};
`
const TypographyLight = styled(Typography)`
  color: ${(props) => props.theme.colors.secondaryText};
`

function Home({ t, i18n }) {
  const imageSlides = [
      {
          image: welcome,
          imageSize: 'contain',
          textPlace: 'flex-start',
          title: t('HomePage.ImageTitle'),
          link: '/appointment',
      linkTitle: t('HomePage.SetAppointmentPartLinkTitle')
      },
      {
      image: emergency,
      title: t('HomePage.Emergency'),
      link: '/emergency',
      linkTitle: t('HomePage.EmergencyLinkTitle')
    }
  ]
  const clinicTreatments = treatments(i18n.language)
  const clinicDoctors = doctors(i18n.language)
  return (
    <div>
      <Carousel
        mobileCount={1}
        tabletCount={1}
        laptopCount={1}
        desktopCount={1}
        showIndicator
        showArrows={false}
      >
        {imageSlides.map((slide, index) => (
          <ImageSection
            key={slide.title + index}
                image={slide.image}
                imageSize={slide.imageSize}
                textPlace={slide.textPlace}
            title={slide.title}
            link={slide.link}
            linkTitle={slide.linkTitle}
          />
        ))}
      </Carousel>
      <Layout>
        <Space medium />
        <Title variant='h2' component='h1' align='center'>
          {t('HomePage.TreatmentPartTitle')}
        </Title>
        <Space large />
        <Grid container justifyContent='center' columnSpacing={10}>
          {clinicTreatments.map((feature, index) => (
            <Grid key={index} item>
              <Feature
                key={index}
                image={feature.image}
                title={feature.title}
                description={feature.description}
              />
            </Grid>
          ))}
        </Grid>
      </Layout>
      <CallToAction>
        <TypographyLight paragraph variant='h3' align='center'>
          {t('HomePage.SetAppointmentPartTitle')}
        </TypographyLight>
        <Button
          href='/appointment'
          variant='outlined'
          disableRipple
          size='large'
          color='secondary'
        >
          {t('HomePage.SetAppointmentPartLinkTitle')}
        </Button>
      </CallToAction>
      <Layout>
        <Space large />
        <Typography paragraph variant='h5'>
          {t('HomePage.ClinicDescription')}
        </Typography>
        <Space large />
      </Layout>
      <Carousel>
        {clinicDoctors.map((doc, index) => (
          <SmallDoctorCard
            isShort={true}
            key={doc.name}
            name={doc.name}
            image={doc.image}
            description={doc.description}
            index={index}
            specialist={doc.specialist}
          />
        ))}
      </Carousel>
      {/*<Layout>*/}
      {/*  <Space large />*/}
      {/*  <Typography paragraph variant='h5'>*/}
      {/*    {t('HomePage.DoctorsDescriptions')}*/}
      {/*  </Typography>*/}
      {/*  <Space large />*/}
      {/*</Layout>*/}
    </div>
  )
}
export default withTranslation()(Home)
