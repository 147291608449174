import React from 'react'
import styled from 'styled-components'
import { Typography, Grid } from '@mui/material'
import { withTranslation } from 'react-i18next'

import { Service } from '../../Components/Service/Service'
import Layout from '../../Components/Layout/Layout'
import { Space } from '../../Components/Space'
import LinkToAppointmentPage from '../Appointment/LinkToAppointmentPage'
import prices from '../../../i18/Datas/prices'

const Container = styled.div``

function Services({ t, i18n }) {
    const servicePrices = prices(i18n.language);
    return (
        <Container>
            <Space medium />
            <Layout>
                <Typography variant='h3' align='center' color='primary'>
                    {t('PricesPage.Title')}
                </Typography>
                <Space medium />
                <Grid container justifyContent='center' columnSpacing={0}>
                    {servicePrices.map((service, index) => (
                        <Grid key={index} item xs={12}>
                            <Service category={service.title} prices={service.prices} />
                        </Grid>
                    ))}
                </Grid>
            </Layout>
            <Space medium />
            <LinkToAppointmentPage />
        </Container>
    )
}

export default withTranslation()(Services)
