import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import {
  MenuRounded as MenuIcon,
  CloseRounded as CloseIcon,
  ChevronRightRounded as ChevronRightIcon
} from '@mui/icons-material'

import { Link } from '../Link'
import { setCurrentLanguage } from '../../../i18/languageStorage'
import { LanguageSelector } from './LanguageSelector'
import englandFlag from '../../Assets/flags/england.svg'
import norwayFlag from '../../Assets/flags/norway.svg'

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 30px;
  color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.background};
  position: relative;
  user-select: none;
`
const EmergancyPageLink = styled(Link)`
  color: #f97402 !important;
  padding : 9px;
`
const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  display: flex;
  svg {
    transition: opacity 200ms ease;
    opacity: 0;
  }
  &.selected {
    color: ${(props) => props.theme.colors.primaryDark};
    svg {
      opacity: 1;
    }
  }
`
const MenuItemsContainer = styled.ul`
  flex-direction: row;
  display: none;
  align-items: center;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    display: flex;
  }
`
const Flag = styled.img`
  width: 29px;
  height: 17px;
  padding: 6px 0;
  object-fit: cover;
  object-position: center;
`
const MenItemsMobile = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    display: none;
  }
`
const DropMenu = styled.div`
  overflow: hidden;
  transition: transform 200ms ease-in-out;
  transform: scaleY(${(props) => (props.open ? 1 : 0)});
  transform-origin: top;
  position: absolute;
  bottom: -${(props) => props.count * 3}em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  left: 0;
  right: 0;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    display: none;
  }
`
const MenuContainerMobile = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100vw;
`
const MenuItemContainer = styled.li`
  height: 3em;
  padding: 0.5em;
  padding-left: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.primaryLight};
  :last-child {
    border-bottom: none;
  }
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    height: 2em;
    border-bottom: none;
  }
`
const flags = {
  en: <Flag src={englandFlag} />,
  no: <Flag src={norwayFlag} />
}
export default function Header({ siteName, pages }) {
  const [isMenuOpen, setMenuOpen] = React.useState(false)
  const toggleMenu = () => setMenuOpen(!isMenuOpen)
  const { i18n } = useTranslation()
  const changeLanguage = (lng) => {
    if (i18n.language === lng) {
      return
    }
    i18n.changeLanguage(lng)
    setCurrentLanguage(lng)
  }
  const languages = [
    {
      name: 'en',
      content: <div>{flags.en}</div>,
      onClick: () => changeLanguage('en')
    },
    {
      name: 'no',
      content: <div>{flags.no}</div>,
      onClick: () => changeLanguage('no')
    }
  ]
  const renderMenuItems = (mobile) => {
    return pages.map((page) => (
      <MenuItemContainer key={page.name}>
        <StyledNavLink
          to={page.url}
          exact={page.exact}
          activeClassName='selected'
          onClick={() => {
            if (mobile && isMenuOpen) {
              setMenuOpen(false)
            }
          }}
        >
          <ChevronRightIcon />
          <Typography variant='body1' component='div' color='currentcolor'>
            {page.name}
          </Typography>
        </StyledNavLink>
      </MenuItemContainer>
    ))
  }
  return (
    <Nav>
      <Typography variant='h6' component='div' color='currentcolor'>
        <Link to={siteName.clinic.link} >{siteName.clinic.title}</Link>
        <EmergancyPageLink to={siteName.emergency.link}>{siteName.emergency.title}</EmergancyPageLink>
      </Typography>
      <MenuItemsContainer>
        {renderMenuItems()}
        {/* <LanguageSelector
          languages={languages}
          currentFlag={flags[i18n.language]}
        /> */}
      </MenuItemsContainer>
      <MenItemsMobile>
        <div>
          {isMenuOpen ? (
            <CloseIcon onClick={toggleMenu} />
          ) : (
            <MenuIcon onClick={toggleMenu} />
          )}
        </div>
        {/* <LanguageSelector
          languages={languages}
          currentFlag={flags[i18n.language]}
        /> */}
      </MenItemsMobile>
      <DropMenu open={isMenuOpen} count={pages.length}>
        <MenuContainerMobile>{renderMenuItems(true)}</MenuContainerMobile>
      </DropMenu>
    </Nav>
  )
}
