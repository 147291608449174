import React from 'react'
import { withTranslation } from 'react-i18next'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider
} from '@mui/material/styles'
import { Typography, Button } from '@mui/material'

import Layout from './Components/Layout'
import Header from './Components/Header'
import Footer from './Components/Footer'

import ScrollToTop from './Utils/ScrollToTopOnPathChange'

import Home from './Pages/Home'
import ContactUs from './Pages/ContactUs'
import Appointment from './Pages/Appointment'
import Doctors from './Pages/Doctors'
import AboutUs from './Pages/AboutUs'
import Services from './Pages/Services'
import Treatments from './Pages/Treatments'
import Treatment from './Pages/Treatment'
import Emergency from './Pages/Emergency'

import { routes } from './routeList.js'

import logo from './Assets/images/clinic-logo.svg'

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}
const fonts = {
  primary: "'Yaldevi', sans-serif",
  secondary: 'cursive'
}
const colors = {
  primary: '#00ACB1',
  primaryDark: '#015D67',
  primaryLight: '#d1fff8',
  accent: '#CAF0C1',
  background: '#FAFCFC',
  primaryText: 'black',
  secondaryText: '#E5EEEF'
}
const emergencyColors = {
  primary: '#f97402',
  primaryDark: '#f84c02',
  primaryLight: '#f99a00',
  accent: '#fbc100',
  background: '#FAFCFC',
  primaryText: 'black',
  secondaryText: '#E5EEEF'
}
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Privacy = styled.div`
  position: fixed;
  width: 100%;
  background: white;
  bottom: 0;
  opacity: 0.9;
`
const PrivacyItemsContainer = styled.div`
  display: flex;
  margin: 2em 0;
  align-items: center;
`
const PRIVACY_KEY = process.env.REACT_APP_PRIVACY_KEY || 'xx_privacy_xx'
const accepted = 'accepted'
const GOOGLE_LINK = process.env.GOOGLE_LINK || 'https://goo.gl/maps/dUw5FrBwMVAg1ysU8'

const theme = Object.assign(
  {},
  {
    colors,
    fonts,
    sizes
  }
)
const materialTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: sizes.mobileL,
      md: sizes.tablet,
      lg: sizes.laptop,
      xl: sizes.laptopL
    }
  },
  typography: {
    fontFamily: fonts.primary
  },
  palette: {
    primary: {
      main: theme.colors.primary
    },
    secondary: {
      main: theme.colors.accent
    },
    background: {
      default: theme.colors.background
    }
  }
})
const emergencyTheme = Object.assign(
  {},
  {
    colors: emergencyColors,
    fonts,
    sizes
  }
)
const emergencyMaterialTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: sizes.mobileL,
      md: sizes.tablet,
      lg: sizes.laptop,
      xl: sizes.laptopL
    }
  },
  typography: {
    fontFamily: fonts.primary
  },
  palette: {
    primary: {
      main: emergencyTheme.colors.primary
    },
    secondary: {
      main: emergencyTheme.colors.accent
    },
    background: {
      default: emergencyTheme.colors.background
    }
  }
})
function App({ t }) {
  const [isPrivacyChecked, setPrivacyChecked] = React.useState(false)
  React.useEffect(() => {
    setPrivacyChecked(localStorage.getItem(PRIVACY_KEY) === accepted)
  }, [])
  function onAccept() {
    setPrivacyChecked(true)
    localStorage.setItem(PRIVACY_KEY, accepted)
  }
  const pages = [
    {
      name: t('Routes.Appointment'),
      url: routes.appointment
    },
    {
      name: t('Routes.Services'),
      url: routes.services
    },
    {
      name: t('Routes.Treatments'),
      url: routes.treatments
    },
    {
      name: t('Routes.ContactUs'),
      url: routes.contactUs
    },
    {
      name: t('Routes.AboutUs'),
      url: routes.aboutUs
    }
  ]
  return (
    <Container>
      <ThemeProvider theme={theme}>
        <MaterialThemeProvider theme={materialTheme}>
          <BrowserRouter>
            <ScrollToTop />
            <span>
              <Header pages={pages} siteName=
                {{
                  clinic: {
                    title: t('SiteName'), 
                    link: '/'
                  }, 
                  emergency: {
                    title: t('Routes.Emergency'), 
                    link:routes.emergency
                  }
                }} />
              <Switch>
                <Route path={routes.home} exact component={Home} />
                <Route path={routes.contactUs} component={ContactUs} />
                <Route path={routes.appointment} component={Appointment} />
                <Route path={routes.treatment} component={Treatment} />
                <Route path={routes.treatments} component={Treatments} />
                <Route path={routes.services} component={Services} />
                <Route path={routes.doctors} component={Doctors} />
                <Route path={routes.aboutUs} component={AboutUs} />
                <ThemeProvider theme={emergencyTheme}>
                  <MaterialThemeProvider theme={emergencyMaterialTheme}>
                    <Route path={routes.emergency} component={Emergency} />
                  </MaterialThemeProvider>
                </ThemeProvider>
              </Switch>
            </span>
            <Footer
              logo={logo}
              siteName={t('SiteName')}
              address={t('Footer.Address')}
              email={t('Footer.Email')}
              telephone={t('Footer.Telephone')}
              followUs={t('Footer.FollowUs')}
              openingHours={t('Footer.OpeningHours')}
              emergencyHours={t('Footer.EmergencyHours')}
              pages={pages}
              googleLink={GOOGLE_LINK}
            />
            {!isPrivacyChecked && (
              <Privacy>
                <Layout>
                  <PrivacyItemsContainer>
                    <Typography
                      paragraph
                      variant='h5'
                      style={{ flex: 1, paddingRight: '1em' }}
                    >
                      {t('PrivacyPolicy.Description')}
                      <a href="https://ec.europa.eu/info/cookies_en">{t('PrivacyPolicy.MoreInfo')}</a>
                    </Typography>
                    <Button
                      href='/'
                      variant='contained'
                      disableRipple
                      size='large'
                      color='primary'
                      onClick={onAccept}
                    >
                      {t('PrivacyPolicy.Button')}
                    </Button>
                  </PrivacyItemsContainer>
                </Layout>
              </Privacy>
            )}
          </BrowserRouter>
        </MaterialThemeProvider>
      </ThemeProvider>
    </Container>
  )
}

export default withTranslation()(App)
