import emergencyOne from "../../App/Assets/images/Emergencies/emergency-1.jpg"
import emergencyTwo from "../../App/Assets/images/Emergencies/emergency-2.jpg"
import emergencyThree from "../../App/Assets/images/Emergencies/emergency-3.jpg"

const emergency = {
    en : {
        title : "Emergency Page",
        description : "Dental emergencies are one reason why it is ideal to see a family dentist for regular dental checkups, because the dentist will have immediate access to your full dental profile, health history, and X-rays. This dentist knows best how to treat any sudden dental emergency that you have, and they will likely be able to see you within 24 hours if their facility also provides urgent dental care",
        telephone: "+47 71 25 15 18",
        gallery : [
            {
                original: emergencyOne,
                thumbnail: emergencyOne
            },
            {
                original: emergencyTwo,
                thumbnail: emergencyTwo
            },
            {
                original: emergencyThree,
                thumbnail: emergencyThree
            }
        ]
    },
    no : {
        title : "nødside",
        description : "Tannlegetilfeller er en grunn til at det er ideelt å oppsøke en tannlege for regelmessige tannkontroller, fordi tannlegen vil ha umiddelbar tilgang til din fullstendige tannprofil, helsehistorie og røntgenbilder. Denne tannlegen vet best hvordan man behandler enhver plutselig tannlegesituasjon du har, og de vil sannsynligvis kunne se deg innen 24 timer hvis klinikken deres også gir akutt tannbehandling",
        telephone : "+47 71 25 15 18",
        gallery : [
            {
                original: emergencyOne,
                thumbnail: emergencyOne
            },
            {
                original: emergencyTwo,
                thumbnail: emergencyTwo
            },
            {
                original: emergencyThree,
                thumbnail: emergencyThree
            }
        ]
    }
}

export default function getEmergencyInfoes(language) {
    return emergency[language]
}
