import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Header = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  height: ${(props) => props.heigh};
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(props) => !props.backgroundSize && 'cover'};
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  margin-bottom: 2em;
  ${(props) =>
    props.backgroundDarkEffect
      ? `&::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: hsl(0deg 0% 0% / 62%);
    pointer-events: none;
  }`
      : ''}
  * {
    z-index: 1;
    user-select: none;
  }
`

function ImageSection ({
    image,
    imageSize,
  backgroundDarkEffect,
  size,
  className,
  imageProps,
  ...props
}) {
  return (
    <Header
          backgroundImage={image}
          backgroundSize={imageSize}
      backgroundDarkEffect={backgroundDarkEffect}
      heigh={size}
      className={className}
      {...imageProps}
    >
      <div className={className ? `${className}-container` : ''}>
        {props.children}
      </div>
    </Header>
  )
}

ImageSection.propTypes = {
  image: PropTypes.string.isRequired,
  backgroundDarkEffect: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  imageProps: PropTypes.object
}
ImageSection.defaultProps = {
  backgroundDarkEffect: false,
  className: ''
}

export default ImageSection
