import React from 'react'
import styled from 'styled-components'
import { Typography, Grid } from '@mui/material'
import { withTranslation } from 'react-i18next'

import Layout from '../../Components/Layout/Layout'
import { Space } from '../../Components/Space'
import { Treatment } from '../../Components/Treatment/Treatment'

import LinkToAppointmentPage from '../Appointment/LinkToAppointmentPage'
import treatments from '../../../i18/Datas/treatments'

const Container = styled.div``

function Treatments({ t, i18n }) {
    const clinicTreatments = treatments(i18n.language)
    return (
        <Container>
            <Space medium />
            <Space medium />
            <Layout>
                <Typography variant='h3' align='center' color='primary'>
                    {t('TreatmentsPage.Title')}
                </Typography>
                <Space medium />
                <Grid container justifyContent='center' rowSpacing={1} columnSpacing={1}>
                    {clinicTreatments.map((service, index) => (
                        <Grid key={index} item>
                            <Treatment {...service} />
                        </Grid>
                    ))}
                </Grid>
            </Layout>
            <Space medium />
            <Space medium />
            <LinkToAppointmentPage />
        </Container>
    )
}

export default withTranslation()(Treatments)
