import React from 'react'
import styled from 'styled-components'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import YouTubeIcon from '@mui/icons-material/YouTube'
import CallIcon from '@mui/icons-material/CallRounded'
import EmailIcon from '@mui/icons-material/EmailRounded'
import LocationIcon from '@mui/icons-material/RoomRounded'
import WebAssetRoundedIcon from '@mui/icons-material/WebAssetRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import ElectricRickshawRoundedIcon from '@mui/icons-material/ElectricRickshawRounded'
import { Typography } from '@mui/material'

import Layout from '../../Components/Layout'
import { Link, Anchor } from '../../Components/Link'

const StyledFooter = styled.footer`
  min-height: 300px;
  width: 100%;
  background: radial-gradient(transparent, #00000040),
    ${(props) => props.theme.colors.primaryDark};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.secondaryText};
`
const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`
const Social = styled.a`
  color: currentColor;
  :visited {
    color: currentColor;
  }
`

const socials = [
  {
    name: 'WhatsApp',
    icon: <WhatsAppIcon fontSize='large' />,
    link: process.env.REACT_APP_WHATSAPP
  },
  {
    name: 'facebook',
    icon: <FacebookIcon fontSize='large' />,
    link: process.env.REACT_APP_FACEBOOK
  },
  {
    name: 'instagram',
    icon: <InstagramIcon fontSize='large' />,
    link: process.env.REACT_APP_INSTAGRAM
  }
  // ,
  // {
  //   name: 'youtube',
  //   icon: <YouTubeIcon fontSize='large' />,
  //   link: process.env.REACT_APP_YOUTUBE
  // }
]
const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2em 0;
  box-sizing: border-box;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    flex-direction: row;
    align-items: flex-end;
    padding: 3em 0;
  }
`
const Socials = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    align-items: left;
  }
`
const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px;
  padding-bottom: 1em;
  margin-bottom: 1em;
  text-align: center;
  svg {
    margin-right: 0.5em;
  }
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    align-items: flex-start;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: left;
    border-bottom: 0;
  }
`
const SocialsAndPagesInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    width: 50%;
  }
`
const Logo = styled.img`
  width: 150px;
  height: 100px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 1em;
`
const Address = styled.address`
  display: flex;
  align-items: center;
  /* div {
    text-align: left;
  } */
`
const OpeningHours = styled(Address)``
const Phone = styled.a`
  text-decoration: none;
  color: currentColor;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Email = styled(Phone)``
const SiteNameContainer = styled.div`
  display: flex;
  align-items: center;
`
const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.background};
  text-decoration: none;
  display: flex;
`
const Pages = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`
const Page = styled.li`
  display: flex;
  padding: 0.5em;
  border-bottom: 1px solid;
  justify-content: center;
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    border: none;
  }
`

export default function Footer({
  logo,
  siteName,
  address,
  googleLink,
  telephone,
  email,
  followUs,
  pages = [],
  openingHours,
  emergencyHours
}) {
  const renderSocials = () => {
    return (
      <Socials>
        <Typography variant='h6' component='div'>
          {followUs}
        </Typography>
        <SocialsContainer>
          {socials.map((social) => (
            <Social key={social.name} href={social.link}>
              {social.icon}
            </Social>
          ))}
        </SocialsContainer>
      </Socials>
    )
  }
  return (
    <StyledFooter>
      <Layout>
        <FooterInfo>
          <ContactInfo>
            {!!logo && (
              <StyledLink to='/'>
                <Logo src={logo} alt={siteName} />
              </StyledLink>
            )}
            <SiteNameContainer>
              <WebAssetRoundedIcon />
              <Typography variant='body1' component='div'>
                {siteName}
              </Typography>
            </SiteNameContainer>
            <Address>
              <LocationIcon />
              <Typography variant='body1' component='div'>
                <Anchor href={googleLink}>{address}</Anchor>
              </Typography>
            </Address>
            <OpeningHours>
              <AccessTimeRoundedIcon />
              <Typography variant='body1' component='div'>
                {openingHours}
              </Typography>
            </OpeningHours>
            <OpeningHours>
              <ElectricRickshawRoundedIcon />
              <Typography variant='body1' component='div'>
                {emergencyHours}
              </Typography>
            </OpeningHours>
            <Phone href={`tel:${telephone}`}>
              <CallIcon />
              <Typography variant='body1' component='div'>
                {telephone}
              </Typography>
            </Phone>
            <Email href={`mailto:${email}`}>
              <EmailIcon />
              <Typography variant='body1' component='div'>
                {email}
              </Typography>
            </Email>
          </ContactInfo>
          <SocialsAndPagesInfo>
            <Pages>
              {pages.map((page) => (
                <Page key={page.name}>
                  <StyledLink to={page.url}>
                    <Typography
                      variant='body1'
                      component='div'
                      fontWeight='bold'
                    >
                      {page.name}
                    </Typography>
                  </StyledLink>
                </Page>
              ))}
            </Pages>
            {renderSocials()}
          </SocialsAndPagesInfo>
        </FooterInfo>
      </Layout>
    </StyledFooter>
  )
}
