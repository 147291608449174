import React from 'react'
import styled from 'styled-components'
import { Typography, Grid } from '@mui/material'

// import doctorImage from '../../Assets/images/doctor.jpeg'
import Layout from '../../Components/Layout'
import { DoctorCard } from '../../Components/DoctorCard'
import { Space } from '../../Components/Space'
import LinkToAppointmentPage from '../Appointment/LinkToAppointmentPage'
import doctors from '../../../i18/Datas/doctors'
import { withTranslation } from 'react-i18next'

const Container = styled.div``

function Doctors({ t, i18n }) {
    const clinicDoctors = doctors(i18n.language)
    return (
        <Container>
            <Layout>
                <Space large />
                <Typography variant='h2' component='h1' color='primary' align='center'>
                    {t('DoctorsPage.Title')}
                </Typography>
                <Typography variant='h5' paragraph align='center'>
                    {t('DoctorsPage.Description')}
                </Typography>
                <Space medium />
                    {clinicDoctors.map((doc, index) => (
                            <DoctorCard
                                key={index}
                                image={doc.image}
                                index={index}
                                name={doc.name}
                                description={doc.description}
                                email={doc.email}
                                specialist={doc.specialist}
                                zoom
                            />
                    ))}
            </Layout>
            <Space medium />
            <LinkToAppointmentPage />
        </Container>
    )
}

export default withTranslation()(Doctors)
