import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'

import { Link } from '../Link'

const Image = styled.div`
  background: linear-gradient(45deg, #00000075, #00000075),
    url(${(props) => props.image});
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: round;
  object-fit: contain;
  object-position: center;
  transition: transform 300ms ease;
`
const Description = styled.div`
  transition: max-height 400ms ease-in;
  max-height: 0px;
  z-index: 1;
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  position: relative;
  width: 320px;
  height: 220px;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: 20px;
  box-sizing: border-box;
  :hover ${Image} {
    transform: scale(1.2);
  }
  :hover ${Description} {
    max-height: 300px;
  }
`
export function Treatment({ image, title, description, link }) {
  const treatment = (
    <Container>
      <Image image={image} />
      <Typography
        variant='h6'
        align='center'
        color='white'
        style={{ zIndex: 1 }}
      >
        {title}
      </Typography>
      <Description>
        <Typography align='center' color='white' style={{ zIndex: 1 }}>
          {description}
        </Typography>
      </Description>
    </Container>
  )
  if (!!link) {
    return <Link to={link}>{treatment}</Link>
  }
  return treatment
}
