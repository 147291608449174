import React from 'react'
import styled from 'styled-components'
import { Typography, Grid } from '@mui/material'
import { withTranslation } from 'react-i18next'
import ElectricRickshawRoundedIcon from '@mui/icons-material/ElectricRickshawRounded'
import CallIcon from '@mui/icons-material/CallRounded'

import { Service } from '../../Components/Service/Service'
import Layout from '../../Components/Layout/Layout'
import { Space } from '../../Components/Space'
import Prices from '../../../i18/Datas/emergencyPrices'
import EmergencyInfoes from '../../../i18/Datas/emergency'
//import ImageGallery from 'react-image-gallery'

const Container = styled.div``
const OpeningHours = styled.div`
  display: flex;
  align-items: center;
`
const ImageGalleryContainer = styled.div`
  .image-gallery-icon {
    :hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  .image-gallery-thumbnail.active {
    border-color: ${(props) => props.theme.colors.primary};
  }
  .image-gallery-thumbnail {
    :hover {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }
`

const Phone = styled.a`
  text-decoration: none;
  color: currentColor;
  display: flex;
  flex-direction: row;
  align-items: center;
`

function Emergency({ t, i18n }) {
 const prices = Prices(i18n.language)
 const infoes = EmergencyInfoes(i18n.language)
  return (
    <Container>
      <Space medium />
      <Layout>
        <Typography variant='h3' align='center' color='primary'>
          {infoes.title}
        </Typography>
        <Space medium />
        <Typography variant='h5' paragraph>
          {infoes.description}
        </Typography>
        <OpeningHours>
          <ElectricRickshawRoundedIcon style={{ marginRight: '1em' }} />
          <Typography variant='h5' paragraph style={{ marginBottom: 0 }}>
            {t('Footer.EmergencyHours')}
          </Typography>
        </OpeningHours>
        <Phone href={`tel:${infoes.telephone}`}>
          <CallIcon style={{ marginRight: '1em' }} />
          <Typography variant='h5' paragraph style={{ marginBottom: 0 }}>
            {infoes.telephone}
          </Typography>
        </Phone>
        <Space medium />
        <Grid container justifyContent='center' columnSpacing={0}>
          {prices.map((service, index) => (
            <Grid key={index} item xs={12}>
              <Service category={service.category} prices={service.prices} />
            </Grid>
          ))}
        </Grid>
        {/*<Space large />*/}
        {/*<ImageGalleryContainer>*/}
        {/*    <ImageGallery items={infoes.gallery} />*/}
        {/*</ImageGalleryContainer>*/}
      </Layout>
      <Space medium />
    </Container>
  )
}

export default withTranslation()(Emergency)
