import React, { useLayoutEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Carousel } from 'react-responsive-carousel'

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
const Indicator = styled.li`
  width: 20px;
  height: 20px;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.primaryLight
      : props.theme.colors.primary};
  display: inline-block;
  cursor: ${(props) => (props.isSelected ? 'default' : 'pointer')};
  border-radius: 10px;
  margin: 0 8px;
`
function CustomCarousel({
  mobileCount,
  tabletCount,
  laptopCount,
  desktopCount,
  showIndicator,
  showArrows,
  ...props
}) {
  const [width] = useWindowSize()
  const centerSlidePercentage = (() => {
    if (width <= Number(props.theme.sizes.mobileL.replace('px', ''))) {
      return 100 / mobileCount
    }
    if (width <= Number(props.theme.sizes.tablet.replace('px', ''))) {
      return 100 / tabletCount
    }
    if (width <= Number(props.theme.sizes.laptop.replace('px', ''))) {
      return 100 / laptopCount
    }
    return 100 / desktopCount
  })()
  return (
    <Carousel
      autoPlay
      centerMode
      infiniteLoop
      showArrows={showArrows}
      swipeable
      emulateTouch
      showStatus={false}
      showIndicators={showIndicator}
      renderIndicator={(onClickHandler, isSelected, index, label) =>
        isSelected ? (
          <Indicator
            isSelected
            aria-label={`Selected: ${label} ${index + 1}`}
            title={`Selected: ${label} ${index + 1}`}
          />
        ) : (
          <Indicator
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role='button'
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        )
      }
      showThumbs={false}
      centerSlidePercentage={centerSlidePercentage}
    >
      {props.children}
    </Carousel>
  )
}
CustomCarousel.defaultProps = {
  mobileCount: 1,
  tabletCount: 1.2,
  laptopCount: 2.1,
  desktopCount: 2.8,
  showIndicator: false,
  showArrows: true
}
export default withTheme(CustomCarousel)
