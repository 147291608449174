import React from 'react'
import styled from 'styled-components'
import {
    Typography,
    Button,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material'
import { withTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import Layout from '../../Components/Layout'
import { Space } from '../../Components/Space'
import { Input, inputModes } from '../../Components/Input'
import { submitAppointmentRequest } from '../../Api'

const Container = styled.div``
const InnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin: auto;
`
const inputs = [
    {
        name: 'fullName',
        label: 'Your full name',
        mode: inputModes.text,
        required: true,
        multiline: false
    },
    {
        name: 'phoneNumber',
        label: 'Phone',
        mode: inputModes.tel,
        required: true,
        multiline: false
    },
    {
        name: 'description',
        label: 'Description',
        mode: inputModes.text,
        required: true,
        multiline: true
    },
    {
        name: 'email',
        label: 'Email',
        mode: inputModes.email,
        required: false,
        multiline: false
    }
    // ,
    // {
    //     name: 'type',
    //     label: 'Type',
    //     mode: inputModes.text,
    //     required: true,
    //     multiline: true,
    //     isSelect: true,
    //     options: [
    //         { value: 1, name: 'Normal' },
    //         { value: 2, name: 'Emergency' }
    //     ]
    // }
]

const StyledButton = styled(Button)`
  @media screen and (min-width: ${(props) => props.theme.sizes.laptop}) {
    width: fit-content;
    align-self: flex-end;
    min-width: 8em !important;
  }
`
const StyledCheckCircleRoundedIcon = styled(CheckCircleRoundedIcon)`
  width: 300px !important;
  height: 300px !important;
  align-self: center;
`

function RequestAppointment({ t }) {
    const recaptchaRef = React.createRef()

    const [isLoading, setLoading] = React.useState(false)
    const [isSnackOpen, setSnackOpen] = React.useState(false) // for fail
    const [successSubmit, setSuccessSubmit] = React.useState(false)
    const [inputValues, setInputValues] = React.useState(
        inputs.map((input) => ({
            name: input.name,
            value: null,
            required: input.required,
            error: ''
        }))
    )
    const onSnackClose = () => {
        setSnackOpen(false)
    }
    const onSubmitClick = async () => {
        const isValid = inputValues.every((input) => {
            if (input.required && !input.value) {
                return false
            }
            return true
        })
        setInputValues(
            inputValues.map((input) => {
                if (input.required && !input.value) {
                    return Object.assign({}, input, {
                        error: 'You have to enter this field'
                    })
                }
                return Object.assign({}, input, {
                    error: ''
                })
            })
        )
        if (!isValid) {
            return
        }

        setLoading(true)
        recaptchaRef.current.reset()
        const token = await recaptchaRef.current.executeAsync()
        const userInfo = {}
        inputValues.forEach((input) => {
            userInfo[input.name] = input.value
        })
        submitAppointmentRequest({ token, ...userInfo })
            .then((res) => {
                setSuccessSubmit(true)
            })
            .catch(() => {
                setSnackOpen(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onTextChange = (event) => {
        const { name, value } = event.target
        setInputValues(
            inputValues.map((i) => {
                if (i.name === name) {
                    return Object.assign({}, i, { value })
                }
                return i
            })
        )
    }
    const renderAppointment = () => {
        if (successSubmit) {
            return (
                <Layout>
                    <Space large />
                    <Space large />
                    <InnerLayout>
                        <StyledCheckCircleRoundedIcon color='primary' />
                        <Typography
                            id='appointment'
                            variant='h4'
                            color='primary'
                            paragraph
                            align='center'
                        >
                            {t('SetAppointment.SuccessMessage')}
                        </Typography>
                    </InnerLayout>
                    <Space large />
                </Layout>
            )
        }
        return (
            <Layout>
                <Space large />
                <Typography
                    id='appointment'
                    variant='h3'
                    color='primary'
                    component='h2'
                    align='center'
                >
                    { t('SetAppointment.Title')}
                </Typography>
                <Typography variant='body1' paragraph align='center'>
                    { t('SetAppointment.Content')}
                </Typography>
                <Space large />
                <InnerLayout>
                    {inputs.map((input, index) => {
                        const currentField = inputValues.find((i) => i.name === input.name)
                        return (
                            <Input
                                key={`${input.name}-${index}`}
                                label={input.label}
                                required={input.required}
                                value={currentField.value}
                                onChange={onTextChange}
                                name={input.name}
                                multiline={input.multiline}
                                error={currentField.error}
                                isSelect={input.isSelect}
                                options={input.options}
                            />
                        )
                    })}
                    <Space medium />
                    <StyledButton
                        variant='contained'
                        disableRipple
                        fullWidth={false}
                        onClick={onSubmitClick}
                        disabled={isLoading}
                    >
                        {isLoading
                            ? (
                                <CircularProgress size={20} color='primary' />
                            )
                            : (
                                t('SetAppointment.Button')
                            )}
                    </StyledButton>
                    <Space large />
                </InnerLayout>
            </Layout>
        )
    }
    return (
        <Container>
            {renderAppointment()}
            <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                badge='bottomright'
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackOpen}
                onClose={onSnackClose}
                message='I love snacks'
            >
                <Alert onClose={onSnackClose} severity='error'>
                    { t('SetAppointment.ErrorMessage')}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default withTranslation()(RequestAppointment)
